import { useTranslation } from "react-i18next";
import { useState } from "react";

import { SectionHeader } from "components/ui/molecules/SectionHeader";
import { PageTitle } from "components/ui/atoms/PageTitle";
import ProfessionalInfo from "./ProfessionalInfo";
import PersonalInfo from "./PersonalInfo";
import AcademicInfo from "./AcademicInfo";
import Security from "./Security";
import Settings from "./Settings";
import Config from "config";

const Account = () => {
  const { t } = useTranslation();

  const SECTIONS = [
    "personal_info",
    "academic_info",
    "professional_info",
    "security",
    "settings",
  ];

  const components = {
    personal_info: <PersonalInfo />,
    academic_info: <AcademicInfo />,
    professional_info: <ProfessionalInfo />,
    security: <Security />,
    settings: <Settings />,
  };

  const [section, setSection] = useState("personal_info");

  return (
    <>
      <div className="flex items-start">
        <div className="flex-1">
          <PageTitle title={t("page_titles.my_account")} />
        </div>
      </div>
      <SectionHeader
        sectionTitles={SECTIONS.map((sec) => t(`${sec}.tab_title`))}
        sectionKeys={SECTIONS}
        section={section}
        setSection={(sec) => {
          setSection(sec);
        }}
      />
      <div className="flex  mb-10">
        <div className="w-full p-4">{components[section]}</div>
      </div>
    </>
  );
};

export default Account;
